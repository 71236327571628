import Chart from 'chart.js';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchBloodPressureLogs } from './api';

export async function loadBloodPressureLogChart(params) {
  const data = await fetchBloodPressureLogs(params);

  return createBloodPressureLogChart(params.canvasContext, data, params.granulation);
}

function createBloodPressureLogChart(ctx, data, granulation) {
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: [{
        data: data.map((datum) => [datum.diastolic_blood_pressure, datum.systolic_blood_pressure]),
        backgroundColor: colors.lightBlue,
        maxBarThickness: 16
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: formatTooltipLabel,
        },
        displayColors: false,
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'mmHg',
          },
          gridLines: {
            drawBorder: false
          }
        }],
        gridLines: {
          color: colors.grey,
        }
      },
    }
  });
}

const formatTooltipLabel = (tooltipItem, data) => {
  const [diastolic, systolic] = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

  return [
    `Systolic: ${systolic}`,
    `Diastolic: ${diastolic}`,
  ];
}
