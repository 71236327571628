import { loadWeightLogChart } from './weight_logs';
import { loadBloodPressureLogChart } from './blood_pressure_logs';
import { loadFluidIntakeLogChart } from './fluid_intake_logs';
import { loadHeartRateLogChart } from './heart_rate_logs';
import { loadMoodLogChart } from './mood_logs';
import { loadFoodLogChart } from './food_logs';
import { loadDrugIntakeLogChart } from './drug_intake_logs';
import { loadSymptomsLogChart } from './symptoms_logs';
import { loadFluidOutputLogChart } from './fluid_output_logs';

export async function loadChart(params) {
  switch (params.type) {
    case 'weight_logs': return loadWeightLogChart(params);
    case 'blood_pressure_logs': return loadBloodPressureLogChart(params);
    case 'fluid_intake_logs': return loadFluidIntakeLogChart(params);
    case 'mood_logs': return loadMoodLogChart(params);
    case 'sodium_logs': return loadFoodLogChart(params, 'sodium');
    case 'carbohydrate_logs': return loadFoodLogChart(params, 'carbohydrate');
    case 'sugar_logs': return loadFoodLogChart(params, 'sugar');
    case 'iron_logs': return loadFoodLogChart(params, 'iron');
    case 'energy_kcal_logs': return loadFoodLogChart(params, 'energy_kcal');
    case 'drug_intake_logs': return loadDrugIntakeLogChart(params);
    case 'symptoms_logs': return loadSymptomsLogChart(params);
    case 'fluid_output_logs': return loadFluidOutputLogChart(params);
    case 'heart_rate_logs': return loadHeartRateLogChart(params);
    default: new Error(`Unknown chart type ${params.chartType}.`);
  }
}
