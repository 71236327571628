import Chart from 'chart.js';
import ColorHash from 'color-hash';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchDrugIntakeLogs } from './api';

export async function loadDrugIntakeLogChart(params) {
  if (params.drugIds.length === 0) {
    return;
  }

  const data = await fetchDrugIntakeLogs(params);

  return createDrugIntakeLogChart(params.canvasContext, data, params.granulation, params.drugIds, params.allDrugs);
}

function createDrugIntakeLogChart(ctx, data, granulation, drugIds, drugs) {
  return new Chart(ctx, {
    type: 'line',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: drugIds.map((drugId) => ({
        data: data.map((datum) => datum.intake_counts[drugId]),
        borderColor: colorHash.hex(drugId),
        backgroundColor: colors.transparent,
        barPercentage: 0.25,
        lineTension: 0,
        label: drugs.find((drug) => String(drug.id) == drugId).name
      })),
    },
    options: {
      responsive: true,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1
          },
          scaleLabel: {
            display: false,
          },
          gridLines: {
            drawBorder: false
          }
        }],
        gridLines: {
          color: colors.grey,
        }
      },
    }
  });
}

const colorHash = new ColorHash();
