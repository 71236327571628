import Chart from 'chart.js';
import ColorHash from 'color-hash';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchSymptomsLogs } from './api';

export async function loadSymptomsLogChart(params) {
  if (params.symptoms.length === 0) {
    return;
  }

  const data = await fetchSymptomsLogs(params);

  return createSymptomsLogChart(params.canvasContext, data, params.granulation, params.symptoms, params.allSymptoms);
}

function createSymptomsLogChart(ctx, data, granulation, symptoms, symptomNames) {
  return new Chart(ctx, {
    type: 'line',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: symptoms.map((symptom) => ({
        data: data.map((datum) => datum.symptom_counts[symptom]),
        borderColor: colorHash.hex(symptom),
        backgroundColor: colors.transparent,
        barPercentage: 0.25,
        lineTension: 0,
        label: symptomNames[symptom]
      })),
    },
    options: {
      responsive: true,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1
          },
          scaleLabel: {
            display: false,
          },
          gridLines: {
            drawBorder: false
          }
        }],
        gridLines: {
          color: colors.grey,
        }
      },
    }
  });
}

const colorHash = new ColorHash();
