import Chart from 'chart.js';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchFoodLogs } from './api';

const nutrientUnits = {
  protein: 'g',
  energy_kcal: 'kcal',
  energy_kj: 'kj',
  sodium: 'mg',
  water: 'g',
  sugar: 'g',
  iron: 'mg',
  carbohydrate: 'g',
  fiber: 'g',
  fat: 'g',
};

export async function loadFoodLogChart(params, attribute) {
  const data = await fetchFoodLogs(params, attribute);

  return createFoodLogChart(params.canvasContext, data, params.granulation, attribute);
}

function createFoodLogChart(ctx, data, granulation, attribute, targetValue = null) {
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: [{
        data: data.map((datum) => datum[attribute]),
        backgroundColor: data.map((datum) => findBarColor(datum[attribute], targetValue)),
        barPercentage: 0.25,
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.value} ${nutrientUnits[attribute]}`,
        },
        displayColors: false,
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: nutrientUnits[attribute]
          },
          gridLines: {
            drawBorder: false
          }
        }],
        gridLines: {
          color: colors.grey,
        }
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'y-axis-0',
          mode: 'horizontal',
          value: targetValue,
          borderColor: colors.blue,
          borderWidth: 1,
          borderDash: [10, 10],
          label: {
            content: 'Target intake',
            backgroundColor: colors.blue,
            enabled: Boolean(targetValue)
          }
        }]
      }
    }
  });
}

const findBarColor = (value, target) => (!target || value <= target) ? colors.lightBlue : colors.red;
