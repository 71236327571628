import Chart from 'chart.js';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchMoodLogs } from './api';

export async function loadMoodLogChart(params) {
  const data = await fetchMoodLogs(params);

  return createMoodLogChart(params.canvasContext, data, params.granulation);
}

function createMoodLogChart(ctx, data, granulation) {
  return new Chart(ctx, {
    type: 'bubble',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: [{
        data: data.map(() => 1),
        backgroundColor: colors.transparent,
        radius: 0,
        pointStyle: data.map((datum) => datum.mood ? moodIcons[datum.mood] : null),
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: false
          },
          display: false,
          gridLines: {
            drawBorder: false
          }
        }],
        gridLines: {
          color: colors.grey,
        }
      },
    }
  });
}

const moodIcons = {
  joyful: createIcon('/images/icon-joyful.svg'),
  content: createIcon('/images/icon-content.svg'),
  indifferent: createIcon('/images/icon-indifferent.svg'),
  sad: createIcon('/images/icon-sad.svg'),
};

function createIcon(src) {
  const image = new Image(25, 25);
  image.src = src;
  return image;
}
