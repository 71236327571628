import Chart from 'chart.js';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchHeartRateLogs } from './api';

export async function loadHeartRateLogChart(params) {
  const data = await fetchHeartRateLogs(params);

  return createHeartRateLogChart(params.canvasContext, data, params.granulation);
}

function createHeartRateLogChart(ctx, data, granulation) {
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: [{
        data: data.map((datum) => datum.heart_rate),
        backgroundColor: colors.blue,
        barPercentage: 0.25,
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 25
          },
          scaleLabel: {
            display: true,
            labelString: 'bpm'
          },
          gridLines: {
            drawBorder: false
          }
        }],
        gridLines: {
          color: colors.grey,
        }
      }
    }
  });
}
