import Chart from 'chart.js';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { isInRange } from '../util/math';
import { fetchWeightLogs } from './api';

export async function loadWeightLogChart(params) {
  const data = await fetchWeightLogs(params);

  return createWeightLogChart(params.canvasContext, data, params.granulation);
}

function createWeightLogChart(ctx, data, granulation, goalWeight = null) {
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: [{
        data: data.map((datum) => datum.weight),
        backgroundColor: data.map((datum) => findBarColor(datum.weight, goalWeight)),
        barPercentage: 0.25,
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'lb'
          },
          gridLines: {
            drawBorder: false
          }
        }],
        gridLines: {
          color: colors.grey,
        }
      },
      annotation: {
        annotations: [goalWeight ? {
          type: 'box',
          drawTime: 'beforeDatasetsDraw',
          borderColor: colors.black,
          borderWidth: 1,
          backgroundColor: colors.lightYellow,
          yScaleID: 'y-axis-0',
          yMin: goalWeight - TARGET_WEIGHT_OFFSET,
          yMax: goalWeight + TARGET_WEIGHT_OFFSET
        } : {}]
      }
    }
  });
}

const TARGET_WEIGHT_OFFSET = 4;

const isInsideTargetZone = (weight, goalWeight) => (
  isInRange(weight, goalWeight - TARGET_WEIGHT_OFFSET, goalWeight + TARGET_WEIGHT_OFFSET)
);

const findBarColor = (weight, goalWeight) => (
  (!goalWeight || isInsideTargetZone(weight, goalWeight)) ? colors.blue : colors.red
);
