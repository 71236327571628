import { subMonths, format } from 'date-fns';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['typeSelect', 'drugSelect', 'symptomSelect', 'drugSelectContainer', 'symptomSelectContainer', 'canvas'];
  static values = {
    type: String,
    monthRange: Number,
    drugs: Array,
    symptoms: Object,
    selectedDrugIds: Array,
    selectedSymptoms: Array,
  };

  connect() {
    $(this.drugSelectTarget).val(this.selectedDrugIdsValue);
    $(this.symptomSelectTarget).val(this.selectedSymptomsValue);

    $(this.typeSelectTarget).on('change', this.handleTypeChange.bind(this));
    $(this.drugSelectTarget).on('change', this.updateChart.bind(this));
    $(this.symptomSelectTarget).on('change', this.updateChart.bind(this));
    this.typeSelectTarget.value = this.typeValue;

    this.updateChart();
  }

  disconnect() {
    $(this.typeSelectTarget).off('change', this.handleTypeChange.bind(this));
    $(this.drugSelectTarget).off('change', this.updateChart.bind(this));
    $(this.symptomSelectTarget).off('change', this.updateChart.bind(this));
  }

  handleTypeChange(event) {
    this.typeValue = event.target.value;
    this.typeSelectTarget.value = this.typeValue;
    this.updateChart();
  }

  handleMonthRangeChange(monthRange) {
    this.monthRangeValue = Number(monthRange);
    this.updateChart();
  }

  async updateChart() {
    $(this.drugSelectContainerTarget).toggle(this.typeValue === 'drug_intake_logs');
    $(this.symptomSelectContainerTarget).toggle(this.typeValue === 'symptoms_logs');

    this.chartController.changeChartType(this.chartArguments);
  }

  get chartArguments() {
    return {
      type: this.typeValue,
      canvasContext: this.canvasContext,
      dateGteq: this.dateGteq,
      dateLteq: this.dateLteq,
      granulation: this.granulation,
      drugIds: this.drugIds,
      allDrugs: this.drugsValue,
      symptoms: this.symptoms,
      allSymptoms: this.symptomsValue,
      chartId: this.chartId,
      monthRange: this.monthRangeValue,
    };
  }

  get canvasContext() {
    return this.canvasTarget.getContext('2d');
  }

  get dateGteq() {
    return format(subMonths(this.today, this.monthRangeValue), 'yyyy-MM-dd');
  }

  get dateLteq() {
    return format(this.today, 'yyyy-MM-dd');
  }

  get granulation() {
    if (this.monthRangeValue < 3) {
      return 'day';
    } else if (this.monthRangeValue < 6) {
      return 'week';
    } else {
      return 'month';
    }
  }

  get drugIds() {
    return $(this.drugSelectTarget).val() || [];
  }

  get symptoms() {
    return $(this.symptomSelectTarget).val() || [];
  }

  get chartId() {
    return this.data.get('id');
  }

  get chartController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'chart');
  }

  get today() {
    return new Date();
  }
}
