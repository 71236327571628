export const xAxisConfig = (granulation) => ({
  type: 'time',
  parser: 'yyyy-MM-dd',
  unitStepSize: 1,
  distribution: 'series',
  time: {
    unit: granulation,
    tooltipFormat: 'MM/dd/yyyy'
  },
  offset: true,
});
