import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const input = $(this.element).find(`input[value="${this.initialValue}"]`);
    this.setActiveInput(input);

    $(this.element).find('input').on('change', this.handleValueChange.bind(this));
  }

  disconnect() {
    $(this.element).find('input').off('change', this.handleValueChange);
  }

  handleValueChange(event) {
    this.setActiveInput(event.target);
    this.updateMonthRange(event.target.value);
  }

  setActiveInput(element) {
    $(this.element).find('label').removeClass('active');
    $(element).parent().addClass('active');
    $(element).attr('checked', 'checked');
  }

  updateMonthRange(value) {
    this.application.controllers
        .filter((controller) => controller.identifier === 'chart-type')
        .forEach((controller) => controller.handleMonthRangeChange(value));
  }

  get initialValue() {
    return this.data.get('initialValue');
  }
}
