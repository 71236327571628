async function fetchData(endpoint, params, otherParams = {}) {
  const urlParams = new URLSearchParams({
    chart_id: params.chartId,
    chart_type: params.type,
    month_range: params.monthRange,
    date_gteq: params.dateGteq,
    date_lteq: params.dateLteq,
    granulation: params.granulation,
    ...otherParams
  });

  const response = await fetch(`/providers/api/${endpoint}.json?${urlParams}`);

  return response.json();
}

export async function fetchWeightLogs(params) {
  return fetchData('weight_logs', params);
}

export async function fetchHeartRateLogs(params) {
  return fetchData('heart_rate_logs', params);
}

export async function fetchBloodPressureLogs(params) {
  return fetchData('blood_pressure_logs', params);
}

export async function fetchFluidIntakeLogs(params) {
  return fetchData('fluid_intake_logs', params);
}

export async function fetchFluidOutputLogs(params) {
  return fetchData('fluid_output_logs', params);
}

export async function fetchMoodLogs(params) {
  return fetchData('mood_logs', params);
}

export async function fetchFoodLogs(params, attribute) {
  return fetchData('food_logs', params, {
    fields: ['date', attribute],
  });
}

export async function fetchDrugIntakeLogs(params) {
  return fetchData('drug_intake_logs', params, {
    drug_ids: params.drugIds,
  });
}

export async function fetchSymptomsLogs(params) {
  return fetchData('symptoms_logs', params, {
    symptoms: params.symptoms,
  });
}
