import Bugsnag from '@bugsnag/js';
import { Controller } from 'stimulus';

import { loadChart } from '../charts';

const DEFAULT_ERROR_MESSAGE = 'An error occurred and this chart could not be displayed. Please check your Internet connection and try again.';

export default class extends Controller {
  static targets = ['canvasContainer'];

  chart;

  async changeChartType(params) {
    if (this.chart) {
      this.chart.destroy();
    }

    this.alertController.clearAlert();
    this.canvasContainerTarget.classList.remove('chart-error');

    try {
      this.chart = await loadChart(params);
    } catch (e) {
      this.alertController.setAlert('error', DEFAULT_ERROR_MESSAGE);
      this.canvasContainerTarget.classList.add('chart-error');

      Bugsnag.notify(e, (event) => {
        event.context = 'Chart load fail';
      });

      return;
    }

    if (params.type === 'blood_pressure_logs') {
      this.alertController.setAlert('info', 'Note: only the first entry has been displayed for each date.');
    }
  }

  get alertController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'alert');
  }
}
