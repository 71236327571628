import Chart from 'chart.js';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchFluidOutputLogs } from './api';

export async function loadFluidOutputLogChart(params) {
  const data = await fetchFluidOutputLogs(params);

  return createFluidOutputLogChart(params.canvasContext, data, params.granulation);
}

function createFluidOutputLogChart(ctx, data, granulation) {
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: [{
        data: data.map((datum) => datum.fluid_output),
        backgroundColor: colors.lightBlue,
        barPercentage: 0.25,
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: formatTooltipLabel,
        },
        displayColors: false,
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'oz'
          },
          gridLines: {
            drawBorder: false
          },
        }],
        gridLines: {
          color: colors.grey,
        }
      },
    }
  });
}

const formatTooltipLabel = (tooltipItem) => `${tooltipItem.value} oz`;
