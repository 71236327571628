import Chart from 'chart.js';

import { colors } from '../util/colors';
import { xAxisConfig } from '../util/chart';
import { fetchFluidIntakeLogs } from './api';

export async function loadFluidIntakeLogChart(params) {
  const data = await fetchFluidIntakeLogs(params);

  return createFluidIntakeLogChart(params.canvasContext, data, params.granulation);
}

function createFluidIntakeLogChart(ctx, data, granulation, fluidIntakeTarget = null) {
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.map((datum) => datum.date),
      datasets: [{
        data: data.map((datum) => datum.fluid_intake),
        backgroundColor: data.map((datum) => findBarColor(datum.fluid_intake, fluidIntakeTarget)),
        barPercentage: 0.25,
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: formatTooltipLabel,
        },
        displayColors: false,
      },
      scales: {
        xAxes: [xAxisConfig(granulation)],
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'oz'
          },
          gridLines: {
            drawBorder: false
          },
        }],
        gridLines: {
          color: colors.grey,
        }
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'y-axis-0',
          mode: 'horizontal',
          value: fluidIntakeTarget,
          borderColor: colors.blue,
          borderWidth: 1,
          borderDash: [10, 10],
          label: {
            content: 'Fluid intake target',
            backgroundColor: colors.blue,
            enabled: Boolean(fluidIntakeTarget)
          }
        }]
      }
    }
  });
}

const formatTooltipLabel = (tooltipItem) => `${tooltipItem.value} oz`;

const findBarColor = (fluidIntake, fluidIntakeTarget) => (
  (!fluidIntakeTarget || fluidIntake <= fluidIntakeTarget) ? colors.lightBlue : colors.red
);
