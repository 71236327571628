import '../initializers/bugsnag';
import '../initializers/chart';

require('@rails/ujs').start();
require('turbolinks').start();

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import 'select2';
import 'bootstrap-datepicker';

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

$(document).on('turbolinks:load', () => {
  $('.js-select').select2({
    width: '100%',
  });

  $('.js-datepicker').datepicker({
    format: 'mm/dd/yyyy',
    forceParse: true,
    endDate: '0d'
  });
});
