import { Controller } from 'stimulus';

const ALERT_CSS_CLASSES = {
  info: 'alert-dark',
  error: 'alert-danger',
};

export default class extends Controller {
  static targets = ['alert'];

  alertType;

  connect() {
    this.clearAlert();
  }

  setAlert(alertType, message) {
    this.clearAlert();

    this.alertType = alertType;
    this.alertTarget.classList.add(ALERT_CSS_CLASSES[this.alertType]);
    this.alertTarget.style.display = 'block';
    this.alertTarget.innerHTML = message;
  }

  clearAlert() {
    if (this.alertType) {
      this.alertTarget.classList.remove(ALERT_CSS_CLASSES[this.alertType]);
    }

    this.alertTarget.style.display = 'none';
    this.alertTarget.innerHTML = '';
    this.alertType = null;
  }
}
